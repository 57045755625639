export class ProductUpdater {
    constructor(formSelector, outputSelector) {
        this.formSelector = formSelector;
        this.forms = Array.from(document.querySelectorAll(formSelector));
        this.output = document.querySelector(outputSelector);

        this.forms.forEach(form => {
            form.addEventListener('input', this.updateOutput.bind(this));
            form.addEventListener('change', this.updateOutput.bind(this));
        });

        // Initial update
        this.updateOutput();

        // Listen for new forms added to DOM
        this.observeDOM();
    }

    observeDOM() {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === "childList") {
                    // Handle added nodes
                    mutation.addedNodes.forEach(node => {
                        if (node.nodeType === Node.ELEMENT_NODE) {
                            let newForms = Array.from(node.querySelectorAll(this.formSelector));
                            if (node.matches(this.formSelector)) {
                                newForms.push(node);
                            }
                            newForms.forEach(form => {
                                if (!this.forms.includes(form)) {
                                    this.forms.push(form);
                                    form.addEventListener('input', this.updateOutput.bind(this));
                                    form.addEventListener('change', this.updateOutput.bind(this));
                                    this.updateOutput(); // Update output when a new form is added
                                }
                            });
                        }
                    });

                    // Handle removed nodes
                    mutation.removedNodes.forEach(node => {
                        if (node.nodeType === Node.ELEMENT_NODE) {
                            let removedForms = Array.from(node.querySelectorAll(this.formSelector));
                            if (node.matches(this.formSelector)) {
                                removedForms.push(node);
                            }
                            removedForms.forEach(form => {
                                let index = this.forms.indexOf(form);
                                if (index !== -1) {
                                    this.forms.splice(index, 1);
                                    this.updateOutput(); // Update output when a form is removed
                                }
                            });
                        }
                    });
                }
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    updateOutput() {
        this.output.innerHTML = ""; // Clear output first
        this.forms.forEach((form, index) => {
            let productName = form.querySelector('select[name="product[]"]').selectedOptions[0]?.text || '-';
            let serialNumber = form.querySelector('input[name="serial-number[]"]').value || '-';
            let description = form.querySelector('textarea[name="error-description[]"]').value || '-';
            let fileUpload = form.querySelector('input[name="file-upload[]"]').files[0];
            let fileUploadText = fileUpload ? `${fileUpload.name}, ${Math.round(fileUpload.size / 1024)} KB` : '-';

            let productDataHTML = `
                <fieldset class="fieldset--data">
                    <div class="fieldset__header">
                        <div class="fieldset__header-title">
                            <h2>${productName}</h2>
                        </div>
                        <div class="fieldset__header-title">
                            <a href="#" class="goto-button" data-step="1">Produkt/e ändern</a>
                        </div>
                    </div>
                    <div class="fieldset__body">

                        <div class="fieldset__data-item fieldset__data-item--full">
                            <div class="fieldset__data-label">
                                Seriennummer
                            </div>
                            <div class="fieldset__data-text">
                                <span class="h2">${serialNumber}</span>
                            </div>
                        </div>

                        <div class="fieldset__data-item fieldset__data-item--full">
                            <div class="fieldset__data-label">
                                Fehlerbeschreibung
                            </div>
                            <div class="fieldset__data-text">
                                ${description}
                            </div>
                        </div>

                        <div class="fieldset__data-item fieldset__data-item--full">
                            <div class="fieldset__data-label">
                                Rechnungsdokument
                            </div>
                            <div class="fieldset__data-text">
                                ${fileUploadText}
                            </div>
                        </div>

                    </div>
                </fieldset>
            `;

            this.output.innerHTML += productDataHTML;
        });
    }
}