// ProductSelector.js
const $ = jQuery;
export default class ProductSelector {
	static instanceCounter = 0;

	constructor(wrapper) {
		this.wrapper = wrapper;
		this.categorySelect = this.wrapper.querySelector(
			".product-selector__category-select"
		);
		this.productSelect = this.wrapper.querySelector(
			".product-selector__product-select"
		);
		this.productImage = this.wrapper.querySelector(
			".product-selector__image"
		);
		this.placeholderImageUrl = this.wrapper.dataset.placeholderImg;

		// Setzen Sie das data-image-id Attribut nur, wenn es noch nicht gesetzt wurde
		if (!this.wrapper.dataset.imageId) {
			this.wrapper.dataset.imageId = ProductSelector.instanceCounter++;
		}

		// Verwenden Sie parseInt(), um den Wert in eine Zahl umzuwandeln
		this.productImageId = this.wrapper.dataset.imageId;
		this.productImage.id = `product-image-${this.productImageId}`;

		this.selectOptions = {
			theme: "products",
			width: "100%",
			searchInputPlaceholder: "Suchen Sie Ihr Produkt …",
			language: {
				noResults: () => {
					return "Keine Übereinstimmungen gefunden";
				},
			},
		};

		this.originalProductOptions = Array.from(
			this.productSelect.querySelectorAll("option:not(:first-child)")
		).map((option) => option.cloneNode(true));

		this.initSelect2();
		this.bindEvents();
		this.showProductImage(this.placeholderImageUrl);

		this.wrapper.classList.add('product-selector--initialized');
	}

	initSelect2() {
		if (this.categorySelect.hasAttribute("data-initialized")) return;

		this.categorySelect.setAttribute("data-initialized", "true");
		$(this.categorySelect).select2(this.selectOptions);

		if (this.productSelect.hasAttribute("data-initialized")) return;

		this.productSelect.setAttribute("data-initialized", "true");
		$(this.productSelect).select2(this.selectOptions);
	}

	bindEvents() {
		$(this.categorySelect).on("change", () => this.handleCategoryChange());
		$(this.productSelect).on("change", () => this.handleProductChange());
	}

	handleCategoryChange() {
		const selectedCategory = this.categorySelect.value;
		this.productSelect.disabled = !selectedCategory;
		this.productSelect.value = "";

		Array.from(
			this.productSelect.querySelectorAll("option:not(:first-child)")
		).forEach((option) => option.remove());

		const matchingOptions = this.originalProductOptions.filter((option) => {
			const category = option.dataset.category;
			return !selectedCategory || category === selectedCategory;
		});

		matchingOptions.forEach((option) => this.productSelect.add(option));

		$(this.productSelect).select2("destroy").select2(this.selectOptions);
		this.showProductImage(this.placeholderImageUrl);
	}

	handleProductChange() {
		const selectedOption =
			this.productSelect.options[this.productSelect.selectedIndex];
		const imageUrl =
			selectedOption.dataset.imgUrl || this.placeholderImageUrl;
		this.showProductImage(imageUrl);
	}

	showProductImage(imageUrl) {
		this.productImage.src = imageUrl;
	}
}
