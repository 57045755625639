// FileUploader.js
export default class FileUploader {
	constructor(uploadAreaSelector) {
		this.uploadAreaSelector = uploadAreaSelector;
		this.initEvents();
	}

	formatFileSize(size) {
		if (size < 1024 * 1024) {
			return (size / 1024).toFixed(1) + " KB";
		} else {
			return (size / (1024 * 1024)).toFixed(1) + " MB";
		}
	}

	isAllowedFileType(fileType) {
		const allowedFileTypes = [
			"application/pdf",
			"image/jpeg",
			"image/jpg",
			"image/png",
		];
		return allowedFileTypes.includes(fileType);
	}

	uploadFile(file) {
		console.log("Datei hochgeladen:", file.name);
		// Hier können Sie den Code zum Hochladen der Datei an den Server hinzufügen
	}

	initEvents() {
		const uploadAreas = document.querySelectorAll(this.uploadAreaSelector);

		uploadAreas.forEach((uploadArea) => {
			if (uploadArea.hasAttribute("data-initialized")) {
				return;
			}

			uploadArea.setAttribute("data-initialized", "true");

			// Drag-and-Drop-Events
			uploadArea.addEventListener("dragover", (event) => {
				event.preventDefault();
				event.target.style.backgroundColor = "#eee";
			});

			uploadArea.addEventListener("dragleave", (event) => {
				event.target.style.backgroundColor = "";
			});

			uploadArea.addEventListener("drop", (event) => {
				event.preventDefault();
				event.target.style.backgroundColor = "";
				const file = event.dataTransfer.files[0];

				if (!this.isAllowedFileType(file.type)) {
					alert(
						"Es sind nur Dateien im Format: PDF, JPEG, JPG oder PNG erlaubt."
					);
					return;
				}

				const info = event.target.querySelector(".upload-area__info");
				info.innerHTML = `${file.name} (${this.formatFileSize(
					file.size
				)}) <a href="#" class="upload-area__remove">Entfernen</a>`;
				info.style.display = "block";
				event.target.querySelector(
					".upload-area__prompt"
				).style.display = "none";
				this.uploadFile(file);
			});

			// Klick-Event
			const link = uploadArea.querySelector(".upload-area__link");
			const input = uploadArea.nextElementSibling;

			link.addEventListener("click", (event) => {
				event.preventDefault();
				input.click();
			});

			input.addEventListener("change", (event) => {
				const file = event.target.files[0];

				if (!this.isAllowedFileType(file.type)) {
					alert(
						"Es sind nur Dateien im Format: PDF, JPEG, JPG oder PNG erlaubt."
					);
					event.target.value = "";
					return;
				}

				const info =
					event.target.previousElementSibling.querySelector(
						".upload-area__info"
					);
				info.innerHTML = `${file.name} (${this.formatFileSize(
					file.size
				)}) <a href="#" class="upload-area__remove">Entfernen</a>`;
				info.style.display = "block";
				event.target.previousElementSibling.querySelector(
					".upload-area__prompt"
				).style.display = "none";
				this.uploadFile(file);
			});

			// Checkbox-Event
			const checkbox =
				uploadArea.nextElementSibling.nextElementSibling.querySelector(
					".upload-area__checkbox"
				);
			checkbox.addEventListener("change", (event) => {
				const prompt = uploadArea.querySelector(".upload-area__prompt");
				const disabledPrompt = uploadArea.querySelector(
					".upload-area__disabled-prompt"
				);
				const info = uploadArea.querySelector(".upload-area__info");

				if (event.target.checked) {
					// input.setAttribute("disabled", "disabled");
					uploadArea.style.backgroundColor = "#eee";
					uploadArea.style.pointerEvents = "none";
					prompt.style.display = "none";
					disabledPrompt.style.display = "inline";
					info.innerHTML = "";
					info.style.display = "none";
					input.value = "";
				} else {
					input.removeAttribute("disabled");
					uploadArea.style.backgroundColor = "";
					uploadArea.style.pointerEvents = "all";
					prompt.style.display = "inline";
					disabledPrompt.style.display = "none";
				}
			});
		});

		// Entfernen-Event
		document.body.addEventListener("click", (event) => {
			if (event.target.classList.contains("upload-area__remove")) {
				event.preventDefault();
				const info = event.target.parentElement;
				info.innerHTML = "";
				info.style.display = "none";
				info.previousElementSibling.style.display = "inline";
				info.closest(".upload-area").nextElementSibling.value = "";
			}
		});
	}
}
