import FileUploader from "./FileUploader.js";
import ProductSelector from "./ProductSelector.js";
import ToggleTextField from "./ToggleTextField.js";
import FormWizard from './FormWizard.js';
import { ContentUpdater } from './ContentUpdater.js';
import { ProductUpdater } from './ProductUpdater.js';

const $ = jQuery;

$(() => {
	$(".select2-text").select2({
		theme: "text",
		dropdownAutoWidth: true,
		width: "auto",
		minimumResultsForSearch: -1,
	});
});

document.addEventListener("DOMContentLoaded", function () {

	const infoBlock = document.querySelector('.site-main__info');
	const helpLinks = document.querySelectorAll('.site-header__info-wrapper .info__link');

	helpLinks.forEach((helpLink) => {
		helpLink.addEventListener('click', (e) => {
			e.preventDefault();
			toggleImageSrc('info__icon', 'icon-info.svg', 'icon-close-outlined.svg');
			infoBlock.classList.toggle('is-visible');
			document.body.classList.toggle('no-scroll');
		});
	});

	function toggleImageSrc(elementClass, fileName1, fileName2) {
		var imgElement = document.querySelector('.' + elementClass);
	  
		if (!imgElement) {
		  console.log('Element mit der Klasse ' + elementClass + ' wurde nicht gefunden.');
		  return;
		}
	  
		var srcArray = imgElement.src.split('/');
		var currentFileName = srcArray[srcArray.length - 1];
		
		var newFileName;
		if (currentFileName === fileName1) {
		  newFileName = fileName2;
		} else {
		  newFileName = fileName1;
		}
		
		srcArray[srcArray.length - 1] = newFileName;
		var newSrc = srcArray.join('/');
		
		imgElement.src = newSrc;
	  }

	function replaceClassInFirstElement(node, oldClassName, newClassName) {
		if (node) {
			node.classList.remove(oldClassName);
			node.classList.add(newClassName);
		}
	}

	function initializeSelect2() {
		const productSelectorWrappers = document.querySelectorAll(
			".single-product__fieldset .product-selector:not(.product-selector--initialized)"
		);

		productSelectorWrappers.forEach(
			(wrapper) => new ProductSelector(wrapper)
		);
	}

	function initializeToggleFields() {
		const toggleFieldElements = document.querySelectorAll(
			".single-product__fieldset .toggle-field"
		);

		toggleFieldElements.forEach((toggleField) => {
			const textField = toggleField.querySelector(".text-field");
			const checkbox = toggleField.querySelector(".checkbox");
			const toggleTextField = new ToggleTextField(textField, checkbox);
		});
	}

	function initializeFileUploader()
	{
		const fileUploader = new FileUploader(".single-product__fieldset .upload-area");
	}

	function addRemoveLink(clone) {
		const removeLink = document.createElement("a");
		removeLink.textContent = "Produkt entfernen";
		removeLink.classList.add("service-form__link", "service-form__remove");
		removeLink.addEventListener("click", function (event) {
			event.preventDefault();
			if (
				confirm(
					"Sind Sie sicher, dass Sie dieses Produkt entfernen möchten?"
				)
			) {
				clone.remove();
			}
		});
		clone.appendChild(removeLink);
	}

	const addButton = document.querySelector('.service-form__add');
	const container = document.querySelector('.productWizard');
	const prototypeFieldset = document.querySelector('.single-product__prototype');

	initializeSelect2();
	initializeToggleFields();
	initializeFileUploader();

	addButton.addEventListener('click', function (event) {
		event.preventDefault();
		const clone = prototypeFieldset.cloneNode(true);

		replaceClassInFirstElement(
			clone,
			'single-product__prototype',
			'single-product__fieldset'
		);

		addRemoveLink(clone);
		addButton.before(clone);

		initializeSelect2();
		initializeToggleFields();
		initializeFileUploader();
	});

	new FormWizard('formWizard', 'stepsNavigation', '.next-step', '.prev-step');

	let updater = new ContentUpdater([
		{inputName: 'first-name', outputId: 'fieldFirstName', type: 'text'},
		{inputName: 'last-name', outputId: 'fieldLastName', type: 'text'},
		{inputName: 'street', outputId: 'fieldStreet', type: 'text'},
		{inputName: 'zipcode', outputId: 'fieldZipCode', type: 'text'},
		{inputName: 'location', outputId: 'fieldLocation', type: 'text'},
		{inputName: 'client-number', outputId: 'fieldClientNumber', type: 'text'},
		{inputName: 'emailnohp', outputId: 'fieldEmail', type: 'text'},
		{inputName: 'phone', outputId: 'fieldPhoneNumber', type: 'text'},
		{inputName: 'company', outputId: 'fieldCompany', type: 'text'},
	]);

	new ProductUpdater('.single-product__fieldset', '.product__output');
});
