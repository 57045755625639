// ToggleTextField.js
export default class ToggleTextField {
	constructor(textField, checkbox) {
		this.textField = textField;
		this.checkbox = checkbox;
		this.init();
	}

	init() {
		if (this.textField.hasAttribute("data-initialized")) {
			return;
		}

		this.textField.setAttribute("data-initialized", "true");

		this.checkbox.addEventListener("change", () => {
			this.textField.disabled = this.checkbox.checked;
			if (this.textField.disabled) {
				this.textField.classList.add("disabled");
				this.textField.value = ""; // Zeile hinzugefügt, um den Text im Textfeld zu entfernen
			} else {
				this.textField.classList.remove("disabled");
			}
		});
	}
}