import Joi from "joi";

class FormWizard {
	static errorMessages = {
		"string.base": "Eingabe muss ein Text sein",
		"string.empty": "Feld ist erforderlich",
		"string.email": "Keine gültige E-Mail-Adresse eingegeben",
		"string.min": "Eingabe muss mindestens {{#limit}} Zeichen lang sein",
		"string.max": "Eingabe darf höchstens {{#limit}} Zeichen lang sein",
		"string.pattern.base": "Die Eingabe hat kein gültiges Format",
		"number.base": "Eingabe darf nur Nummern enthalten",
		"any.required": "Feld ist erforderlich",
		"any.invalid": "Feld ist erforderlich",
	};

	static getGermanErrorMessage(error) {
		console.log(error.type);
		const messageTemplate = this.errorMessages[error.type] || error.message;
		return messageTemplate.replace(/{{#label}}/g, error.context.label);
	}

	constructor(
		formContainerId,
		navigationContainerId,
		nextButtonClass,
		prevButtonClass
	) {
		this.container = document.getElementById(formContainerId);
		this.steps = Array.from(
			this.container.getElementsByClassName("formStep")
		);
		this.navigation = document.getElementById(navigationContainerId);
		this.nextButtons = document.querySelectorAll(nextButtonClass);
		this.prevButton = document.querySelector(prevButtonClass);
		this.prevButton.addEventListener("click", this.previousStep.bind(this));
		this.currentStep = 0;

		this.nextButtons.forEach((button) => {
			button.addEventListener("click", this.nextStep.bind(this));
		});

		this.initNavigation();
		this.showStep();

		this.goToButtons = document.querySelectorAll(".goto-button");
		this.goToButtons.forEach(this.addButtonListener.bind(this));
		this.observeDom();
	}

	addButtonListener(button) {
		const step = button.getAttribute("data-step");
		button.addEventListener(
			"click",
			(event) => {
				event.preventDefault();
				this.goTo(step);
			},
			true
		);
	}

	observeDom() {
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === "childList") {
					mutation.addedNodes.forEach((node) => {
						if (node.nodeType === Node.ELEMENT_NODE) {
							if (node.matches(".goto-button")) {
								this.addButtonListener(node);
							} else {
								node.querySelectorAll(".goto-button").forEach(
									this.addButtonListener.bind(this)
								);
							}
						}
					});
				}
			});
		});
		observer.observe(document.body, { childList: true, subtree: true });
	}

	initNavigation() {
		this.steps.forEach((step, index) => {
			const stepTitle = step.getAttribute("data-title");

			const navItem = document.createElement("div");
			navItem.className = "nav-item";

			const navLink = document.createElement("a");
			navLink.href = "#";
			navLink.className = "nav-item__link";

			const navStep = document.createElement("div");
			navStep.className = "nav-item__step";
			navStep.textContent = `Schritt ${index + 1}:`;

			const navTitle = document.createElement("div");
			navTitle.className = "nav-item__title";
			navTitle.textContent = stepTitle;

			navLink.appendChild(navStep);
			navLink.appendChild(navTitle);
			navItem.appendChild(navLink);
			this.navigation.appendChild(navItem);

			navLink.addEventListener("click", (event) => {
				event.preventDefault();
				if (index <= this.currentStep) this.goTo(index);
			});

			this.infoSteps = Array.from(
				document.querySelectorAll(".site-main__area-info")
			);
			this.showInfoStep();
		});
	}

	showStep() {
		this.steps.forEach((step, index) => {
			step.style.display = index === this.currentStep ? "block" : "none";
		});

		Array.from(this.navigation.children).forEach((navItem, index) => {
			const navLink = navItem.querySelector(".nav-item__link");
			navLink.classList.toggle("active", index === this.currentStep);
			if (index < this.currentStep) {
				navLink.classList.add("enabled");
			} else {
				navLink.classList.remove("enabled");
			}
		});

		this.nextButtons.forEach((button) => {
			let buttonText = button.querySelector("span");
			buttonText.textContent =
				this.currentStep === this.steps.length - 1
					? "Abschicken"
					: "Nächster Schritt";
		});

		this.togglePrevButton();
	}

	showInfoStep() {
		this.infoSteps.forEach((step) => (step.style.display = "none"));

		const currentInfoStep = this.infoSteps.find(
			(step) =>
				parseInt(step.getAttribute("data-info-step")) ===
				this.currentStep
		);
		if (currentInfoStep) {
			currentInfoStep.style.display = "block";
		}
	}

	nextStep() {
		if (this.validate(this.currentStep)) {
			if (this.currentStep === this.steps.length - 1) {
				this.submit();
			} else {
				this.currentStep++;
				this.showStep();
				this.showInfoStep();
			}
		}
	}

	previousStep() {
		if (this.currentStep > 0) {
			this.currentStep--;
			this.showStep();
			this.showInfoStep();
		}

		this.togglePrevButton();
	}

	togglePrevButton() {
		if (this.currentStep === 0) {
			this.prevButton.style.display = "none";
		} else {
			this.prevButton.style.display = "flex";
		}
	}

	goTo(step) {
		this.currentStep = parseInt(step);
		this.showStep();
		this.showInfoStep();
	}

	validate(index) {
		const step = this.steps[index];
		const inputs = step.querySelectorAll("input, select, textarea");
		let isValid = true;
		let hasScrolled = false;

		inputs.forEach((input) => {
			const existingError =
				input.parentNode.querySelector(".error-message");
			if (existingError) {
				input.parentNode.removeChild(existingError);
			}

			const value = input.value;
			const ruleRequired = input.getAttribute("data-rule-required");
			const ruleType = input.getAttribute("data-rule-type");
			const ruleMinLength = input.getAttribute("data-rule-minlength");
			const ruleMaxLength = input.getAttribute("data-rule-maxlength");

			if (!ruleRequired && !ruleMinLength && !ruleMaxLength) {
				return;
			}

			let schema;

			if (!ruleType) {
				schema = Joi.string().allow("");
			} else if (ruleType == "email") {
				schema = Joi.string().email({ tlds: { allow: false } });
			} else if (ruleType == "phone") {
				schema = Joi.string().pattern(
					/(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/
				);
			} else if (ruleType == "number") {
				schema = Joi.number();
			} else if (ruleType == "toggle-field") {
				schema = Joi.object({
					"toggle-serial-number": Joi.boolean().required(),
					"serial-number": Joi.string().when("checkbox", {
						is: false,
						then: Joi.required(),
					}),
				});
			}

			if (ruleRequired === "true") {
				schema = schema.disallow("").required();
			}

			if (ruleMinLength) {
				schema = schema.disallow("").min(Number(ruleMinLength));
			}

			if (ruleMaxLength) {
				schema = schema.disallow("").max(Number(ruleMaxLength));
			}

			const { error } = schema.validate(value);
			if (error) {
				isValid = false;

				const errorMessage = document.createElement("div");
				errorMessage.className = "error-message";
				errorMessage.textContent = FormWizard.getGermanErrorMessage(
					error.details[0]
				);
				input.after(errorMessage);

				if (!isValid && !hasScrolled) {
					input.scrollIntoView({
						behavior: "smooth",
						block: "center",
					});
					hasScrolled = true;
				}
			}
		});

		return isValid;
	}

	showError(input, message) {
		const errorDiv = document.createElement("div");
		errorDiv.className = "input-error";
		errorDiv.textContent = message;
		input.parentNode.insertBefore(errorDiv, input);
	}

	submit() {
		this.container.submit();
	}
}

export default FormWizard;
