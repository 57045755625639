export class ContentUpdater {
	constructor(fields) {
		this.fields = fields.map((field) => {
			return {
				inputElement: document.getElementsByName(field.inputName)[0],
				outputElement: document.getElementById(field.outputId),
				fieldType: field.type,
			};
		});
		this.fields.forEach((field) => {
			field.outputElement.innerHTML = field.inputElement.value || "-";
		});
		this.registerInputEvents();
	}

	registerInputEvents() {
		this.fields.forEach((field) => {
			switch (field.fieldType) {
				case "text":
				case "select":
					field.inputElement.addEventListener("input", () => {
						field.outputElement.innerHTML = this.generateOutput(
							field.inputElement.value
						);
					});
					break;
				case "file":
					field.inputElement.addEventListener("change", () => {
						if (field.inputElement.files.length > 0) {
							let fileName = field.inputElement.files[0].name;
							let link = URL.createObjectURL(
								field.inputElement.files[0]
							);
							field.outputElement.innerHTML = this.generateOutput(
								`<a href="${link}">${fileName}</a>`
							);
						} else {
							field.outputElement.innerHTML = "-";
						}
					});
					break;
			}
		});
	}

	generateOutput(value) {
		return value || "-";
	}
}
